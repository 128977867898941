<template>
  <div class="body-wrapper">
    <Headernav />
    <div class="w-full">
      <div class="pt-12 pb-12">
        <div class="container text-center md:pt-12 md:pb-12">
          <div class="font-bold text-3xl text-gray-800 mb-3">MESSAGE SENDING SUCCESSFUL!</div>
          <div class="text-xl text-gray-600 mb-12">Thanks for your kind interest in Firmalo Plastic Surgery. Our team will reach out to you in a while.</div>
          <div class="btn-width m-auto"><router-link class="m-auto" to="/"><div class="gold-btn">Back To Home</div></router-link></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias for src
import Headernav from '@/components/Headernav.vue'
export default {
  components: {
    Headernav
  },
  mounted () {
    gtag('event', 'conversion', {'send_to': 'AW-783577726/2_XxCJTPsIsBEP7k0fUC'});
  }
}
</script>
<style>
</style>
